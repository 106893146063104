<template>
  <div>
    <PublishSteps></PublishSteps>
    <Form
      ref="formValidate"
      :model="formValidate"
      :rules="ruleValidate"
      :label-width="80"
    >
      <div class="main-content">
        <PageSection>
          <template v-slot:title>需求信息</template>
          <FormItem label="求购标题" prop="title">
            <Input
              v-model="formValidate.title"
              placeholder="请输入标题"
              style="width: 50%"
              class="ivu-input-fix"
            ></Input>
          </FormItem>
          <Table
            border
            :columns="columns"
            :data="productList"
            :fixed-shadow="'auto'"
          >
            <template #categoryId="{ index }">
              <Cascader
                :model-value="productList[index].categorySelect"
                :data="tableObj.categoryOptions"
                :transfer="true"
                trigger="hover"
                @on-change="
                  tableSelectChange({
                    val: $event,
                    index,
                    type: columnType.category,
                  })
                "
              ></Cascader>
              <span
                v-if="productList[index].categoryInvalide"
                class="table_validate"
              >
                商品分类不能为空
              </span>
            </template>
            <template #productName="{ index }">
              <Select
                v-model="productList[index].productName"
                :transfer="true"
                @on-change="
                  tableSelectChange({
                    val: $event,
                    index,
                    type: columnType.productName,
                  })
                "
              >
                <Option
                  v-for="item in productList[index].productNameOptions"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </Option>
              </Select>
              <span
                v-if="productList[index].productNameInvalide"
                class="table_validate"
              >
                品名不能为空
              </span>
            </template>
            <template #formate="{ index }">
              <Select
                v-model="productList[index].formate"
                :transfer="true"
                @on-change="
                  tableSelectChange({
                    val: $event,
                    index,
                    type: columnType.formate,
                  })
                "
              >
                <Option
                  v-for="item in productList[index].formateOptions"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </Option>
              </Select>
              <span
                v-if="productList[index].formateInvalide"
                class="table_validate"
              >
                规格不能为空
              </span>
            </template>
            <template #material="{ index }">
              <Select
                v-model="productList[index].material"
                :transfer="true"
                @on-change="
                  tableSelectChange({
                    val: $event,
                    index,
                    type: columnType.material,
                  })
                "
              >
                <Option
                  v-for="item in productList[index].materialOptions"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </Option>
              </Select>
              <span
                v-if="productList[index].materialInvalide"
                class="table_validate"
              >
                材质不能为空
              </span>
            </template>
            <template #count="{ index }">
              <div style="display: flex">
                <InputNumber
                  v-model="productList[index].count"
                  placeholder="请输入"
                  :min="0"
                  @on-change="weightNumberPriceInput({ index })"
                  @on-blur="weightNumberPriceInput({ index })"
                ></InputNumber>
                <Select
                  v-model="productList[index].unitId"
                  :transfer="true"
                  placeholder="单位"
                  style="width: 50%"
                  @on-change="
                    tableSelectChange({
                      val: $event,
                      index,
                      type: columnType.unit,
                    })
                  "
                >
                  <Option
                    v-for="item in tableObj.unitOptions"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </Option>
                </Select>
              </div>
            </template>
            <template #price="{ index }">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <InputNumber
                  v-model="productList[index].price"
                  :style="{ width: productList[index].unitId ? '60%' : '100%' }"
                  :min="0"
                  @on-change="weightNumberPriceInput({ index })"
                  @on-blur="weightNumberPriceInput({ index })"
                ></InputNumber>
                <span v-if="productList[index].unitId">
                  {{ '元/' + productList[index].priceUnit }}
                </span>
              </div>
            </template>
            <template #totalPrice="{ row }">
              <div
                style="
                  color: #de6a64;
                  font-size: 16px;
                  height: 32px;
                  line-height: 32px;
                "
              >
                {{ row.totalPrice }}
              </div>
            </template>
            <template #depositValue="{ row }">
              <div style="height: 32px; line-height: 32px">
                {{ row.depositValue }}
              </div>
            </template>
            <!-- <template #action="{ row, index }">
              <Button
                type="text"
                size="small"
                style="color: #80beff; background: none; box-shadow: none"
                @click="deleteRow({ row, index })"
              >
                删除
              </Button>
            </template> -->
          </Table>
          <!-- <Button class="add-button" @click="addRow()">继续添加</Button> -->
        </PageSection>
        <PageSection>
          <template v-slot:title>交易信息</template>
          <FormItem label="配送方式" prop="deliveryType">
            <RadioGroup v-model="formValidate.deliveryType">
              <Radio :label="1">买家自提</Radio>
              <Radio :label="2">商家配送</Radio>
            </RadioGroup>
          </FormItem>
        </PageSection>
        <PageSection>
          <template v-slot:title>交易模式</template>
          <FormItem label="付款方式" prop="deliveryType">
            <RadioGroup v-model="formValidate.tradeType">
              <Radio label="1">全款</Radio>
              <Radio label="2" :disabled="isDisabled">保证金</Radio>
            </RadioGroup>
          </FormItem>
        </PageSection>
        <PageSection>
          <template v-slot:title>收货信息</template>
          <FormItem label="收货仓库" prop="warehouseName">
            <Input
              v-model="formValidate.warehouseName"
              placeholder="请输入收货仓库"
              style="width: 40%"
            ></Input>
          </FormItem>
          <FormItem label="收货地址" prop="warehouseAddress">
            <Input
              v-model="formValidate.warehouseAddress"
              type="textarea"
              :rows="4"
              placeholder="请输入收货地址"
              style="width: 50%"
            ></Input>
          </FormItem>
        </PageSection>
        <PageSection>
          <template v-slot:title>联系信息</template>
          <FormItem label="姓名" prop="name">
            <Input
              v-model="formValidate.name"
              placeholder="请输入姓名"
              style="width: 40%"
            ></Input>
          </FormItem>
          <FormItem label="电话" prop="tel">
            <Input
              v-model="formValidate.tel"
              placeholder="请输入电话"
              style="width: 40%"
            ></Input>
          </FormItem>
          <FormItem label="邮箱" prop="email">
            <Input
              v-model="formValidate.email"
              placeholder="请输入邮箱"
              style="width: 40%"
            ></Input>
          </FormItem>
          <FormItem label="备注" prop="remark">
            <Input
              v-model="formValidate.remark"
              type="textarea"
              :rows="4"
              placeholder="请输入备注"
              style="width: 40%"
            ></Input>
          </FormItem>
        </PageSection>

        <div class="submit-button">
          <div class="submit-totalprice">
            <span>支付押金</span>
            <span class="totalprice-number">￥{{ earnestMoney }}</span>
          </div>
          <div class="submit-describe">
            <span>(生成订单或者取消订单时,押金会退回账户)</span>
          </div>
          <Button type="error" style="width: 150px" @click="toPublish">
            立即发布
          </Button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import PublishSteps from './components/PublishSteps'
import { initialProductTable } from './config'
import { toRaw } from 'vue'

export default {
  name: 'PublishNeeds',
  data () {
    return {
      productList: [], // 表格数据
      categoryTreeList: [], // 原始商品分类数据
      isDisabled: false,
      tableObj: {
        // 表格对象
        categoryOptions: [], // 商品分类级联下拉框数据
        unitOptions: [],
        depositList: [], // 所有保证金比例
      },
      formValidate: {
        title: '', // 产品名称
        deliveryType: 1, // 配送方式
        tradeType: 1, // 付款模式
        warehouseName: '', // 收货仓库
        warehouseAddress: '', // 收件详细地址
        name: '', // 采购人
        tel: '', // 收件人手机号
        email: '', // 邮箱
        remark: '', // 备注
      },
      ruleValidate: {
        title: [
          { required: true, message: '求购标题不能为空', trigger: 'blur' },
        ],
        deliveryType: [
          {
            required: true,
            message: '配送方式不能为空',
            trigger: 'blur',
            type: 'number',
          },
        ],
        tradeType: [
          {
            required: true,
            message: '付款模式不能为空',
            trigger: 'blur',
            type: 'number',
          },
        ],
        warehouseName: [
          { required: true, message: '收货仓库不能为空', trigger: 'blur' },
        ],
        warehouseAddress: [
          { required: true, message: '收货地址不能为空', trigger: 'blur' },
        ],
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        tel: [{ required: true, message: '电话不能为空', trigger: 'blur' }],
        email: [{ required: false }],
        remark: [{ required: true, message: '备注不能为空', trigger: 'blur' }],
      },
      earnestMoney: 0,
      columnType: {
        // 表头类型,类似enum用法，判断用
        category: 1, // 商品分类
        productName: 2, // 品名
        formate: 3, // 规格
        material: 4, // 材质
        unit: 5, // 单位
      },
    }
  },
  setup () {
    // 初始化表格
    const columns = initialProductTable()
    return {
      columns,
    }
  },
  created () {
    this.getCategory()
    this.getUnits()
    this.init()
  },
  methods: {
    init () {
      const tableInfo = {
        // 表格所需字段
        categorySelect: [], // 每行选中的商品分类
        categoryId: '',
        subCategoryId: '',
        productName: '', // 每行选中的品名
        formate: '', // 规格
        unitId: '', // 单位id
        unitName: '', // 单位名
        formateOptions: [], // 规格下拉框
        productNameOptions: [], // 品名下拉框
        materialOptions: [], // 材质下拉框
        count: 0, // 数量
        totalPrice: 0, // 总金额
        price: 0, // 单价
        priceUnit: '', // 单价单位
        depositId: '', // 保证金Id
        depositValue: '', // 保证金比例
        depositAfterCount: 0, // 保证金数值
        material: '', // 材质
        categoryInvalide: false,
        productNameInvalide: false,
        formateInvalide: false,
        materialInvalide: false,
      }
      // tableInfo.sort = this.productList.length + 1
      this.productList.push(tableInfo)
    },
    getUnits () {
      this.$store
        .dispatch('Units')
        .then((response) => {
          const unitOptions = []
          Object.keys(response.result).forEach((item) => {
            unitOptions.push({
              value: item,
              label: response.result[item],
            })
          })
          this.tableObj.unitOptions = unitOptions
        })
        .catch(() => {})
    },
    getCategory () {
      this.$store
        .dispatch('CategoryTree')
        .then((response) => {
          this.categoryTreeList = response.result
          response.result.forEach((item) => {
            const children = []
            item.subCategoryList.forEach((i) => {
              children.push({
                value: i.categoryId,
                label: i.categoryName,
              })
            })
            // 获取所有分类信息
            this.tableObj.categoryOptions.push({
              value: item.deposit.categoryId,
              label: item.deposit.categoryName,
              children,
            })
            // 获取所有保证金信息
            this.tableObj.depositList.push({
              [item.deposit.categoryId]: {
                depositId: item.deposit.depositId,
                depositValue: item.deposit.depositValue,
              },
            })
          })
        })
        .catch((e) => {})
    },
    /**
     * 需求信息表格删除行
     */
    deleteRow ({ index }) {
      this.productList = this.productList.filter(
        (item, _index) => _index !== index,
      )
    },
    /**
     * 需求信息表格新增行
     */
    addRow () {
      const tableInfo = {
        // 表格所需字段
        categorySelect: [], // 每行选中的商品分类
        categoryId: '',
        subCategoryId: '',
        productName: '', // 每行选中的品名
        formate: '', // 规格
        unitId: '', // 单位id
        unitName: '', // 单位名
        formateOptions: [], // 规格下拉框
        productNameOptions: [], // 品名下拉框
        materialOptions: [], // 材质下拉框
        count: 0, // 数量
        totalPrice: 0, // 总金额
        price: 0, // 单价
        priceUnit: '', // 单价单位
        depositId: '', // 保证金Id
        depositValue: '', // 保证金比例
        depositAfterCount: 0, // 保证金数值
        material: '', // 材质
        tradeType: 1,
        categoryInvalide: false,
        productNameInvalide: false,
        formateInvalide: false,
        materialInvalide: false,
      }
      tableInfo.sort = this.productList.length + 1
      this.productList.push(tableInfo)
    },
    /**
     * 需求信息下拉框选择
     */
    tableSelectChange ({ val, index, type }) {
      // 选择单位时，获取选择的单位名
      if (type === this.columnType.unit) {
        this.tableObj.unitOptions.forEach((item) => {
          if (item.value === val) {
            this.productList[index].unitName = item.label
            this.productList[index].priceUnit = item.label
          }
        })
      } else {
        if (this.productList[index].categorySelect.length === 0) {
          this.productList[index].categoryInvalide = false
        }
        if (this.productList[index].productName) {
          this.productList[index].productNameInvalide = false
        }
        if (this.productList[index].formate) {
          this.productList[index].formateInvalide = false
        }
        if (this.productList[index].material) {
          this.productList[index].materialInvalide = false
        }
      }

      if (type === this.columnType.category) {
        this.productList[index].productName = ''
        this.productList[index].material = ''
        this.productList[index].formate = ''
        this.productList[index].categorySelect = val
        this.productList[index].categoryId = val[0]
        this.productList[index].subCategoryId = val[1]
        this.getGetDeposit(val, index)
        this.getProductName(val, index)
        this.weightNumberPriceInput({ index })
      } else if (type === this.columnType.productName) {
        this.productList[index].material = ''
        this.productList[index].formate = ''
        this.getFormate(val, index)
      } else if (type === this.columnType.formate) {
        this.productList[index].material = ''
        this.getMaterials(val, index)
      }
    },
    getProductName (val, index) {
      const postData = {
        subCategoryId: val && val[1],
      }
      this.$store
        .dispatch('ProductNames', postData)
        .then((response) => {
          this.productList[index].productNameOptions = response.result
        })
        .catch(() => {})
    },
    /* 获取所有保证金 */
    getGetDeposit (val, index) {
      this.tableObj.depositList.forEach((item) => {
        if (item[val[0]]) {
          this.productList[index].depositId = item[val[0]].depositId
          this.productList[index].depositValue = item[val[0]].depositValue
        }
      })
    },

    getFormate (val, index) {
      const postData = {
        subCategoryId: this.productList[index].categorySelect[1],
        productName: val,
      }
      this.$store
        .dispatch('Formates', postData)
        .then((response) => {
          this.productList[index].formateOptions = response.result
        })
        .catch(() => {})
    },

    getMaterials (val, index) {
      const postData = {
        subCategoryId: this.productList[index].categorySelect[1],
        productName: this.productList[index].productName,
        formate: val,
      }
      this.$store
        .dispatch('Materials', postData)
        .then((response) => {
          this.productList[index].materialOptions = response.result
        })
        .catch(() => {})
    },
    /*
     * 计算总金额和保证金
     */
    weightNumberPriceInput ({ index }) {
      const productList = this.productList
      productList[index].count = Number(productList[index].count)
      productList[index].totalPrice =
        Math.round(productList[index].count * productList[index].price * 100) /
        100
      // 计算保证金
      let depositAfterCount =
        productList[index].totalPrice *
        (Number(productList[index].depositValue) / 100)
      if (Number(productList[index].depositValue) === 0) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }

      depositAfterCount = Math.round(depositAfterCount * 100) / 100
      productList[index].depositAfterCount = depositAfterCount

      // 计算总保证金
      const earnestMoney = this.productList.reduce((total, item) => {
        return (
          total + Number(item.totalPrice) * (Number(item.depositValue) / 100)
        )
      }, 0)
      this.earnestMoney = Math.round(earnestMoney * 100) / 100
    },
    /**
     * 点击发布
     */
    toPublish () {
      this.$refs.formValidate.validate((valid) => {
        // 验证表格和表单其他必填项
        const tableValid = this.validateTable()
        if (!valid || tableValid) {
          this.$Message.error('请填写必填项!')
          return
        }
        this.setPublish()
      })
    },
    setPublish () {
      const params = Object.assign({}, this.formValidate)
      const productList = this.productList
      productList[0].tradeType = this.formValidate.tradeType
      params.preOrderGoodsList = toRaw(productList)
      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h(
              'div',
              {
                style: {
                  color: '#d12b23',
                  fontSize: '22px',
                },
              },

              '正在启动银行支付请不要离开页面。否则无法完成支付...',
            ),
          ])
        },
      })
      console.log('params', params)
      this.$store.dispatch('publish/SavePreOrder', params).then((res) => {
        // this.$Spin.hide()
        if (res.returnCode === '1') {
          if (!res.result.isNeedPay) {
            this.$Spin.hide()
            this.$Notice.success({
              title: '成功',
              desc: '发布成功. ',
            })
            this.$router.push('/publish/needslist')

            return
          }
          if (res.result.authUrl) {
            window.open(res.result.authUrl, '_self')
          }
          // this.$router.push('/publish/needslist')
          // this.$Notice.success({
          //   title: '成功',
          //   desc: '采购单发布成功. ',
          // })
        }
      })
    },
    /**
     * 验证表格必填项
     */
    validateTable () {
      let inValide = false
      this.productList.forEach((item) => {
        if (item.categorySelect.length === 0) {
          item.categoryInvalide = true
        } else {
          item.categoryInvalide = false
        }
        if (!item.productName) {
          item.productNameInvalide = true
        } else {
          item.productNameInvalide = false
        }
        if (!item.formate) {
          item.formateInvalide = true
        } else {
          item.formateInvalide = false
        }
        if (!item.material) {
          item.materialInvalide = true
        } else {
          item.materialInvalide = false
        }
      })
      this.productList.forEach((item) => {
        if (
          item.categoryInvalide ||
          item.productNameInvalide ||
          item.formateInvalide
        ) {
          inValide = true
          return inValide
        }
      })
      return inValide
    },
  },
  components: {
    PublishSteps,
  },
}
</script>

<style lang="less" scoped>
.main-content {
  margin-top: 30px;

  .table_validate {
    color: red;
  }
}

.add-button {
  margin-top: 10px;
  width: 100%;
}

.submit-button {
  border-top: 1px solid #f1f2f5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;

  .submit-totalprice {
    margin-bottom: 5px;
    font-size: 16px;
    .totalprice-number {
      color: #de6a64;
      font-size: 16px;
      padding: 0 10px;
    }
  }

  .submit-describe {
    margin-bottom: 10px;
    color: #909399;
    font-size: 12px;
  }
}
</style>
