<template>
  <div class="steps-container">
    <div v-for="(item, index) of steps" :key="index" class="steps-item">
      <span class="step-number">{{ item.stepNumber }}</span>
      <span class="step-text"> {{ item.stepText }}</span>
      <img v-if="index !== steps.length-1" class="step-arrow" :src="require('/static/icons/right.png')" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublishSection',
  data () {
    return {
      steps: [{
        stepNumber: 1,
        stepText: '发起需求'
      },
      {
        stepNumber: 2,
        stepText: '等待卖家报价'
      },
      {
        stepNumber: 3,
        stepText: '沟通确认'
      },
      {
        stepNumber: 4,
        stepText: '撮合成功'
      }]
    }
  },
}
</script>

<style lang="less">
  .steps-container {
    background-color: #f5f7fa;
    height: 50px;
    display: flex;
    align-items: center;
    .steps-item {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 14px;
      position: relative;
      .step-number {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #585859;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        margin-right: 10px;
      }
      .step-text {
        color: #444445;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }
      .step-arrow {
        position: absolute;
        right: 0;
      }
    }
  }
</style>
